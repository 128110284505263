import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const OCOGSupport = () => (
  <Layout>
    <SEO title="One Community One Goal" />
    <div className="container primary-bg">
      <div className="row">
        <div className="col">
          <h1>Local Support through One Community One Goal</h1>
          <p>
            Coming soon.
          </p>
          <Link className="full-cta" to="/">Go back to the homepage</Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default OCOGSupport
